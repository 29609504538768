/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Gtm from "src/components/Gtm";
import Page from "./Page";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="pt" />
          <body
            className="html not-front not-logged-in no-sidebars page-node page-node- page-node-9 node-type-page"
            style={{
              "background-color": "#0B1B07"
            }}
          />
          <meta httpEquiv="content-type" content="text/html;charset=utf-8" />
          <link
            rel="shortcut icon"
            href="/sites/all/themes/responsive/favicon.ico"
            type="image/vnd.microsoft.icon"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <link rel="canonical" href="/cookie-policy" />
          <link rel="shortlink" href="/cookie-policy" />
          <title>Política De Cookies | www.biafine.pt</title>
          <style
            type="text/css"
            media="all"
            cssText='
	
	@import url("/css/system.menus.css");
	
	
'
          />
          <style
            type="text/css"
            media="screen"
            cssText='
	
	@import url("/sites/all/modules/contrib/flexslider/assets/css/flexslider_img.css");
'
          />
          <style
            type="text/css"
            media="all"
            cssText='
	
	
	
	
	
	
'
          />
          <style
            type="text/css"
            media="all"
            cssText='
	@import url("/sites/all/modules/contrib/ctools/css/ctools.css");
'
          />
          <style
            type="text/css"
            media="all"
            cssText='
	@import url("/sites/all/themes/responsive/css/responsive-style.css");
	
	@import url("/sites/all/themes/responsive/flexslider.css");
	@import url("/sites/all/themes/responsive/style.css");
	@import url("/sites/default/files/color/responsive/colors.css");
'
          />
          
          
          
          <script type="text/javascript" src="/js/jquery.js" />
          <script type="text/javascript" src="/js/jquery.once.js" />
          
          <script
            type="text/javascript"
            src="/sites/all/themes/responsive/js/jquery.mobilemenu.js"
          />
          <script
            type="text/javascript"
            src="/sites/all/themes/responsive/js/superfish.js"
          />
          <script
            type="text/javascript"
            src="/sites/all/themes/responsive/js/custom.js"
          />
        
        </Helmet >
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
